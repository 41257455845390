import { cn } from "@/lib/utils";
import {
    Chip,
    Theme
} from "@mui/material";
import { SxProps } from "@mui/system";
import moment from "moment";
import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";

moment.locale();

interface Module {
    title?: string | React.ReactNode;
    sx?: SxProps<Theme>;
    sxCardContent?: any;
    children?: React.ReactNode;
    key?: string;
    action?: React.ReactNode;
    className?: string;
    counter?: string | number | boolean;
    classNameHeaderContainer?: string,
    counterColor?:
    | "default"
    | "info"
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning";
    id?: string;
    titleElement?: JSX.Element;
    cardStyle?: string;
    rounded?: boolean;
}

function Module({
    title,
    titleElement,
    className,
    sxCardContent,
    children,
    key,
    action,
    counter,
    counterColor = "info",
    classNameHeaderContainer = '',
    id,
    cardStyle,
    ...rest
}: Module) {
    return (
        <Card
            className={cn(`border-none p-0 md:mx-6 mx-2`, cardStyle)}
            style={{
                boxShadow: '2px 2px 10px rgba(0 0 0 / 5%)'
            }}
            {...rest}
            key={key}
            id={id}
        >
            {title || !!titleElement ? (
                <CardHeader className="p-0 ">
                    <div className={cn('flex w-full items-center sm:h-[60px] h-[60px] justify-between px-4', classNameHeaderContainer)}>
                        <CardTitle className="sm:text-[18px] text-[16px]">
                            {title}
                            {titleElement ?? <></>}
                            {counter && (
                                <Chip
                                    size="small"
                                    sx={{ ml: 1 }}
                                    color={counterColor}
                                    label={counter.toString()}
                                />
                            )}
                        </CardTitle>
                        {action}
                    </div>
                </CardHeader>
            ) : (
                ""
            )}
            <CardContent
                className={cn("p-0 grid py-3 px-3 sm:px-5 h-fit", className)}
                style={{ ...sxCardContent }}
            >
                {children}
            </CardContent>
        </Card>
    );
}

export default Module;