import { Button } from "../ui/button"
import { Skeleton } from "../ui/skeleton"

export function PaginationCard({
    leftDisabled,
    rightDisabled,
    onPrevPagination,
    onNextPagination,
    PaginationCount,
    isLoading
}) {
    return <div
        className="w-full mt-4 px-6 py-2 rounded-lg border items-center justify-between flex  border-gray-300 shadow-2x relative">
        <div>
            {
                isLoading
                    ? <Skeleton className="h-5 w-44" />
                    : PaginationCount
            }
        </div>
        <div className="flex items-center justify-end gap-2">
            <ButtonPagination
                disabled={!!leftDisabled}
                right={false}
                onClick={() => onPrevPagination()}
            />
            <ButtonPagination
                disabled={!!rightDisabled}
                right={true}
                onClick={() => onNextPagination()}
            />
        </div>

    </div>
}

export function ButtonPagination({ onClick, disabled, right = true }) {
    return <Button
        variant="california"
        className="shrink-0 h-[33px]"
        type="button"
        size={'sm'}
        onClick={onClick}
        disabled={disabled}>
        {
            right ? 'Siguiente' : 'Anterior'
        }
    </Button>
}
