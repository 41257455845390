import { Skeleton } from "@/components/ui/skeleton";

export function SkeletonChart() {
    return <div className="flex flex-col w-full 2xl:h-[230px] sm:h-[220px] h-[300px] mb-2">
        <div role="status" className="rounded-sm p-3 flex flex-col gap-4 w-full h-full border">
            <div className='flex flex-col gap-2'>
                <Skeleton className="h-[7px] w-[50%]" />
                <Skeleton className="h-[7px] w-[30%]" />
            </div>
            <div className="flex flex-grow">
                <Skeleton className="h-full w-full" />
            </div>
        </div>
    </div>
}
