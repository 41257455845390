import { TabsTrigger } from "@/components/ui/tabs";
import { PropsTab } from "@/interfaces/Interface";

export default function TabModify({ value, label }: PropsTab) {
    return (
        <TabsTrigger
            value={value}
            className="data-[state=active]:bg-primary  data-[state=active]:text-white text-xs bg-white data-[state=active]:shadow-none"
        >
            {label}
        </TabsTrigger>
    )
}
