import { TailwindColor } from '@/components/ui/chip';
import { SurveyStatus } from '@/interfaces/Interface';
import { Angry, Frown, Meh, Smile } from 'lucide-react';
import { ReactNode } from 'react';
import { SurvayType } from "../surverysList/SurvayItemCard";

export const netPromotersData = {
    promoters: {
        label: "Promotores",
        color: "#4ade80",
        key: 'promoters'
    },
    passives: {
        label: "Pasivos",
        color: "#fb923c",
        key: 'passives'
    },
    detractors: {
        label: "Detractores",
        color: "#f87171",
        key: 'detractors'
    }
}

export const sruveyStatusData: Record<SurveyStatus, {
    label: string,
    color: TailwindColor
}> = {
    completed: {
        color: 'green',
        label: 'Completado'
    },
    dismissed: {
        color: 'gray',
        label: 'Despedido'
    },
    sended: {
        color: 'amber',
        label: 'Enviado'
    },
    error: {
        color: 'red',
        label: 'Error'
    },
    pendingDispatch: {
        color: "purple",
        label: 'Pendiente de enviar'
    },
    completing: {
        color: "blue",
        label: 'Completando'
    }
};

export const surveyData = {
    statusData: sruveyStatusData,
    actionsData: {
        call: {
            label: 'Realizar llamada'
        },
        message: {
            label: "Enviar mensaje"
        },
        not_action: {
            label: ""
        }
    },
    actionStatusData: {
        todo: {
            label: "A realizar",
            color: 'pink'
        },
        canceled: {
            label: 'Cancelado',
            color: 'red'
        },
        done: {
            label: "Realizada",
            color: 'green'
        },
        doing: {
            label: 'Haciendo',
            color: 'amber'
        }
    },
    typeData: {
        detractor: {
            icon: <Angry size={16} className="text-red-500 ml-1" />,
            label: 'Detractor'
        },
        passive: {
            icon: <Frown size={16} className="text-orange-500 ml-1" />,
            label: 'Pasivo'
        },
        promoter: {
            icon: <Smile size={16} className="text-green-500 ml-1" />,
            label: 'Promotor'
        },
        undefined: {
            icon: <Meh size={16} className="text-gray-400 ml-1" />,
            label: 'Indefinido'
        }
    }
}

export const actionStatius = Object.entries(surveyData.actionStatusData).map(([code, data]) => ({
    ...data,
    code
}))

export const npsCategoryData: Record<SurvayType, {
    icon: ReactNode,
    label: string,
    color: string
}> = {
    undefined: {
        color: 'text-gray-500',
        label: 'Sin respuesta',
        icon: <Meh size={15} />
    },
    detractor: {
        color: 'text-red-500',
        label: 'Detractor',
        icon: <Angry size={15} />
    },
    passive: {
        color: 'text-orange-500',
        label: 'Pasivo',
        icon: <Frown size={15} />
    },
    promoter: {
        color: 'text-green-500',
        icon: <Smile size={15} />,
        label: 'Promotor'
    }
};