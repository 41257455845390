import { createContext, useContext, useState, ReactNode } from "react";

interface GoalModalContextType {
    contactId: string | undefined;
    setContactId: (id: string | undefined) => void;
}

const GoalModalContext = createContext<GoalModalContextType | undefined>(undefined);

export const GoalModalProvider = ({ children }: { children: ReactNode }) => {
    const [contactId, setContactId] = useState<string | undefined>(undefined);

    return (
        <GoalModalContext.Provider value={{ contactId, setContactId }}>
            {children}
        </GoalModalContext.Provider>
    );
};

export const useGoalModal = () => {
    const context = useContext(GoalModalContext);
    if (!context) {
        throw new Error("useContact must be used within a ContactProvider");
    }
    return context;
};
