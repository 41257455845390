import React from 'react'
import { Input } from '../ui/input'
import { Search } from 'lucide-react'

interface ISearchForm {
    value: string
    handleChange: (value: string) => void
    placeholder: string
}

export default function SearchForm({ value, handleChange, placeholder }: ISearchForm) {
    return <form onSubmit={(e) => e.preventDefault()} className="h-[40px] py-[2px] m-0 flex items-center border border-input rounded-md">
        <Input
            autoComplete="off"
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            autoFocus
            className="border-none placeholder:text-xs focus-visible:ring-0 focus-visible:ring-offset-0"
        />
        <button className='text-primary mr-3' aria-label="search">
            <Search size={15} />
        </button>
    </form>
}
