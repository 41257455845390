import { getCompany } from "@/actions/auth";
import { columnsCotactsWithAdditionalData } from "@/components/company/uploadExel/libs/constants/columns";
import { Button } from "@/components/ui/button";
import { generateDuration } from "@/helpers/generateDurationFormat";
import { Box } from "@mui/material";
import _ from "lodash";
import { MessageSquare } from "lucide-react";
import moment from "moment";
import { useGoalModal } from "../GoalModalContext";

const validityStates = {
    'not_time': {
        label: 'Tiempo insuficiente'
    },
    'not_answer': {
        label: 'Sin respuesta'
    },
    'not_client': {
        label: 'Sin cliente'
    },
    'not_record': {
        label: 'Sin registro'
    },
    'valid': {
        label: 'Sin registro',
        color: '#4caf50'
    }
}

const tracingMediums = {
    'call': 'Llamada',
    'chat': 'WhatsApp',
    'visit': 'Visita',
    'email': 'Email'
}

const tracing = [
    {
        id: 'actions',
        cell: ({ row }) => {
            const { setContactId } = useGoalModal();
            const contactId = row.original.contact
            return <Button
                variant="outline"
                disabled={!contactId}
                onClick={() => setContactId(contactId)}
            >
                <MessageSquare size={14} />
            </Button>
        }
    },
    {
        accessorKey: "date",
        header: "Fecha",
        cell: ({ row }) => {
            return moment(row.getValue('date')).format("DD/MM/YYYY");
        },
    },
    {
        accessorKey: "phone",
        header: "Teléfono",
        cell: ({ row }) => {
            const phone = row.getValue('phone')
            const validity = row.getValue('validity')

            if (!getCompany().hideNotClient || validity != 'not_client') return phone

            if (phone && phone.length > 4) return new Array(phone.length - 3).join('#')
                + phone.substring(phone.length - 4, phone.length)

            return ''
        },
    },
    {
        accessorKey: "prospectName",
        header: "Nombre contacto",
        cell: ({ row }) => {
            const prospectName = row.getValue('prospectName')
            if (!getCompany().hideNotClient || row.original.validity != 'not_client') return prospectName
            return 'No es cliente'
        },
    },
    {
        accessorKey: "maxDuration",
        header: "Duración",
        cell: ({ row }) => {
            const value = row.getValue('maxDuration')
            return generateDuration(Number(value))
        }
    },
    {
        accessorKey: "validity",
        header: "Valido",
        cell: ({ row }) => {
            const value = row.getValue('validity')
            const { color, label } = validityStates[value]
            return <Box color={color || '#ef5350'}>{label}</Box>
        }
    },
    {
        accessorKey: "validBy",
        header: "Medio",
        cell: ({ row }) => {
            const value = row.getValue('validBy')
            return tracingMediums[value] || value
        }
    },
    {
        accessorKey: "user",
        header: "Vendedor",
        cell: ({ row }) => {
            const { lastname, name } = row.original.user || {}
            return `${name} ${lastname}`
        }
    },
    ..._.get(getCompany(), 'additionalUserFields', []).filter(ele => ele.type == 'select').map((ele) => ({
        accessorKey: "user.additionalData." + ele.key,
        header: ele.title,
        cell: ({ row }) => {
            let value = _.get(row.original, 'user.additionalData.' + ele.key, '')
            return value || ''
        }
    })),
]

const sale = [
    {
        id: 'actions',
        cell: ({ row }) => {
            const { setContactId } = useGoalModal();
            const contactId = row.original.contact_id
            return <Button
                variant="outline"
                disabled={!contactId}
                onClick={() => setContactId(contactId)}
            >
                <MessageSquare size={14} />
            </Button>
        }
    },
    {
        accessorKey: "dateOfAdmission",
        header: "Fecha ingreso",
        cell: ({ row }) => {
            const sale = row.original
            return moment(sale.date).format('DD/MM/YYYY')
        },
    },
    {
        accessorKey: "saleDate",
        header: "Fecha de venta",
        cell: ({ row }) => {
            const sale = row.original
            return moment(sale.date_sale).format('DD/MM/YYYY')
        },
    },
    {
        accessorKey: "seller",
        header: "Vendedor",
        cell: ({ row }) => {
            const sale = row.original
            return `${sale.user.name} ${sale.user.lastname}`
        },
    },
    {
        accessorKey: "id",
        header: "ID",
        cell: ({ row }) => {
            const sale = row.original
            return sale.ticket_id
        },
    },
    {
        accessorKey: "saleID",
        header: "ID Venta",
        cell: ({ row }) => {
            const sale = row.original
            return sale.ticket_sale_id
        },
    }, {
        accessorKey: "phone",
        header: "Teléfono",
        cell: ({ row }) => {
            const sale = row.original
            return sale.phone
        },
    },
    {
        accessorKey: "sold",
        header: "Vendido",
        cell: ({ row }) => {
            const sale = row.original
            return sale.sold ? 'Vendido' : 'No vendido'
        },
    },
    {
        accessorKey: "state",
        header: "Estado",
        cell: ({ row }) => {
            const sale = row.original
            return sale.status
        },
    },
]

export const tableColums = {
    'tracing': tracing,
    'sale': sale,
    'contacts': [
        {
            id: 'actions',
            cell: ({ row }) => {
                const { setContactId } = useGoalModal();
                const contactId = row.original._id
                return <Button
                    variant="outline"
                    disabled={!contactId}
                    onClick={() => setContactId(contactId)}
                >
                    <MessageSquare size={14} />
                </Button>
            }
        },
        ...columnsCotactsWithAdditionalData],
}
