import { getCompany } from "@/actions/auth";
import Chip, { TailwindColor } from "@/components/ui/chip";
import { getCompanyGroupConfig } from "@/helpers/companyGroups";
import { Feature, isFeatureEnabled } from '@/helpers/featureFlagHelpers';
import { generateDuration } from "@/helpers/generateDurationFormat";
import dayjs from "dayjs";
import _ from "lodash";
import moment from "moment";
import { textTypeInEs } from ".";
import { surveyData } from '../../../../comercial/netPromotersScore/utils/constants';
import { ItemAgentForColumns } from '../../../../item-agent-for-columns';
import { formatDate } from "../helpers";

export const columnsCalls = [
    {
        "accessorKey": "phoneNumber",
        "header": "Teléfono",
        cell: ({ row }) => <div>{row.getValue('phoneNumber')}</div>
    },
    {
        "accessorKey": "name",
        "header": "Nombre",
        cell: ({ row }) => <div>{row.getValue('name')}</div>
    },
    {
        "accessorKey": "duration",
        "header": "Duración",
        cell: ({ row }) => <div>{generateDuration(row.getValue('duration'))}</div>
    },
    {
        "accessorKey": "dateTime",
        "header": "Fecha y hora",
        cell: ({ row }) => <div>{formatDate(row.getValue('dateTime'))}</div>
    },
    {
        "accessorKey": "agent",
        "header": "Vendedor",
        cell: ({ row }) => {
            const agent = row.getValue('agent')
            return <div>{typeof agent !== 'object' ? agent : `${agent.name} ${agent.lastname}`}</div>
        }
    },
    {
        "accessorKey": "type",
        "header": "Tipo",
        cell: ({ row }) => <div>{textTypeInEs[row.getValue('type')] || ''}</div>
    }
].map((data) => ({ ...data, header: <div>{data.header}</div> }))


export const columnsCotacts = ({ company }) => [
    {
        "accessorKey": "createdAt",
        "header": "Fecha de creación",
        cell: ({ row }) => {
            const value = row.getValue('createdAt')
            if (!value) return null;
            return <div>{
                dayjs(value).format('DD/MM/YY HH:mm:ss')
            }</div>
        }
    },
    {
        "accessorKey": "firstName",
        "header": "Nombre",
    },
    {
        "accessorKey": "lastName",
        "header": "Apellido",
        cell: ({ row }) => {
            return row?.original?.lastName || '';
        }
    },
    {
        "accessorKey": "phones",
        "header": "Teléfono",
        cell: ({ row }) => {
            const value = row.getValue('phones')
            const phones = Array.isArray(value) ? value.join(', ') : value
            return <div>{phones}</div>
        }
    },
    {
        "accessorKey": "emails",
        "header": "Emails",
        cell: ({ row }) => {
            const value = row.getValue('emails')
            const emails = Array.isArray(value) ? value.join(', ') : value
            return <div>{emails}</div>
        }
    },
    {
        "accessorKey": "statusTag",
        "header": "Etiqueta",
        cell: ({ row }) => {
            const statusTags = _.get(company, 'statusTags', [])
            let statusTag = row.getValue('statusTag')
            statusTag = statusTags.find(status => status.code === statusTag)?.name || statusTag
            return statusTag
        }
    },
    {
        "accessorKey": "detail",
        "header": "Comentario",
    },
    {
        "accessorKey": "status",
        "header": "Estado",
        cell: ({ row }) => {
            const status = row.getValue('status')
            const { color, label } = getContactStatusData(status)
            if (!color || !label || !status) return null;

            return <Chip color={color as TailwindColor} withCircle={true}>
                {label}
            </Chip>
        }
    },
    {
        "accessorKey": "agent",
        "header": "Vendedor",
        cell: ({ row }) => {
            const agent = row.getValue('agent')
            return <div>{_.get(agent, 'name', '')} {_.get(agent, 'lastname', '')}</div>
        }
    },
    {
        "accessorKey": "origin",
        "header": "Orígen",
        cell: ({ row }) => {
            const origin = row.getValue('origin')
            const findOrigin = _.get(company, 'origins', []).find((o) => o.code === origin)
            return <div>{findOrigin ? findOrigin.name : (origin?.originText || origin)}</div>
        }
    }
]

const companyGroupField = [
    {
        "accessorKey": "affiliatedCompanyGroup",
        "header": getCompanyGroupConfig().title.plural,
        cell: ({ row }) => {
            const companyGroups = _.get(row.original, 'affiliatedCompanyGroupPreview', []);

            return companyGroups.map((company) => {
                return company?.companyGroup?.businessName || company?.companyGroup?.taxCode || company?.companyGroup?._id;
            }).join(', ');
        }
    },
    {
        "accessorKey": "role",
        "header": `Roles`,
        cell: ({ row }) => {
            return _.get(row.original, 'affiliatedCompanyGroupPreview', []).map((companyGroup) => {
                return companyGroup.role;
            }).join(', ');
        }
    },
]

if (isFeatureEnabled(Feature.ENABLE_COMPANY_GROUP_SECTORS)) {
    companyGroupField.push({
        "accessorKey": "sector",
        "header": "Sectores",
        cell: ({ row }) => {
            return _.get(row.original, 'affiliatedCompanyGroupPreview', []).map((companyGroup) => {
                return companyGroup?.sector?.name;
            }).filter(Boolean).join(', ');
        }
    })
}

const affiliatedCompanyGroupFields = isFeatureEnabled(Feature.ENABLE_BUSINESS_SALES_MODEL) ? companyGroupField : [];

export const columnsCotactsWithAdditionalData = [
    ...columnsCotacts({ company: getCompany() }),
    ...affiliatedCompanyGroupFields,
    ...(_.get(getCompany(), 'additionalDataFields', [])
        .filter(field => field.active)
        .map((field) => {
            return {
                accessorKey: 'contactAdditionalFields.' + field.code,
                header: field.name,
                cell: ({ row }) => {
                    const value = _.get(row.original['contactAdditionalFields'], field.code, '') || _.get(row.original['additionalData'], field.code, '')

                    return value
                }
            }
        }))
]

export const columnsSale = [
    {
        "accessorKey": "ticket_id",
        "header": "ID",
    },
    {
        "accessorKey": "ticket_sale_id",
        "header": "ID venta",
    },
    {
        "accessorKey": "date",
        "header": "Fecha de ingreso",
    },
    {
        "accessorKey": "date_sale",
        "header": "Fecha de venta",
    },
    {
        "accessorKey": "status",
        "header": "Estado Venta",
        cell: ({ row }) => {
            const { sold, cancel } = row.original || {}
            const { color, label } = getDataStatusSale(!sold && !cancel ? 'prospect' : sold ? 'sale' : 'cancel')
            return <Chip color={color as TailwindColor} withCircle={false}>
                {label}
            </Chip>
        }
    },
    {
        "accessorKey": "agent",
        "header": "Vendedor",
        cell: ({ row }) => {
            const agent = row.getValue('agent')
            const dataToShow = typeof agent === 'object' ? `${agent.name} ${agent.lastname}` : agent
            return <div>{dataToShow}</div>
        }
    },
    {
        "accessorKey": "user_code",
        "header": "Codigo Vendedor",
    },
    {
        "accessorKey": "phone",
        "header": "Teléfono",
    },
    {
        "accessorKey": "name",
        "header": "Nombre",
    },
    {
        "accessorKey": "client_id",
        "header": "ID Cliente",
    },
    {
        "accessorKey": "type",
        "header": "Tipo",
    },
    {
        "accessorKey": "state",
        "header": "Departamento/Provincia",
    },
    {
        "accessorKey": "city",
        "header": "Ciudad",
    },
    {
        "accessorKey": "gov_id",
        "header": "CI/DNI",
    },
    {
        "accessorKey": "cancel_reason",
        "header": "Razon de cancelado",
    },
    {
        "accessorKey": "product",
        "header": "Producto",
    },
    {
        "accessorKey": "category",
        "header": "Categoria",
    },
    {
        "accessorKey": "value",
        "header": "Precio",
    },
    {
        "accessorKey": "importation",
        "header": "Importación",
    }
].map((data) => ({ ...data, header: <div>{data.header}</div> }))

export const columnsCompany = [
    {
        "accessorKey": "businessName",
        "header": "Nombre",
    },
    {
        "accessorKey": "displayName",
        "header": "Razón Social",
    },
    {
        "accessorKey": "origin",
        "header": "Origen",
    },
    {
        "accessorKey": "taxCode",
        "header": "RUT",
    },
    {
        "accessorKey": "description",
        "header": "Descripción",
    },
    ...(_.get(getCompany(), 'additionalCompanyFields', [])
        .filter(field => field.active)
        .map((field) => {
            return {
                accessorKey: 'additionalData.' + field.code,
                header: field.name,
                cell: ({ row }) => {
                    const value = _.get(row.original['additionalData'], field.code, '') || _.get(row.original['additionalData'], field.code, '')

                    if (!value) return null;

                    switch (field.type) {
                        case 'date': {
                            const dateValue = new Date(value);
                            return (dateValue instanceof Date && !isNaN(dateValue.getTime()))
                                ? dayjs(dateValue).format('DD/MM/YYYY')
                                : null
                        }
                        case 'multiselect': {
                            return [value].flat().join(', ')
                        }
                    }

                    return value
                }
            }
        }))
]

export const npsSurveysColumns = [
    {
        "accessorKey": "sendedDate",
        "header": "Fecha de envío",
        "cell": ({ row }) => {
            const sendedDate = row.getValue('sendedDate');
            return sendedDate ? moment(sendedDate).format('DD/MM/YYYY') : null
        }
    },
    {
        "accessorKey": "completedDate",
        "header": "Fecha de completado",
        "cell": ({ row }) => {
            const completedDate = row.getValue('completedDate');
            return completedDate ? moment(completedDate).format('DD/MM/YYYY') : null
        }
    },
    {
        "accessorKey": "contact",
        "header": "Contacto",
        "cell": ({ row }) => {
            const contact = row.getValue('contact');
            return [contact?.firstName, contact?.lastName].filter(Boolean).join(' ')
        }
    },
    {
        "accessorKey": "response",
        "header": "Respuesta",
    },
    {
        "accessorKey": "scale",
        "header": "Escala",
    },
    {
        "accessorKey": "createdBy",
        "header": "Creado por",
        "cell": ({ row }) => {
            const createdBy = row.getValue('createdBy');
            if (!createdBy?.name) return null;

            return createdBy
                ? <ItemAgentForColumns
                    agent={createdBy}
                    hiddenWithOutAgent={true}
                />
                : null
        }
    },
    {
        "accessorKey": "action",
        "header": "Acción a Realizar",
        "cell": ({ row }) => {
            const action = row.getValue('action');
            return surveyData.actionsData?.[action]?.label || ''
        }
    },
    {
        "accessorKey": "actionStatus",
        "header": "Estado de la Acción",
        "cell": ({ row }) => {
            const actionStatus = row.getValue('actionStatus');
            return surveyData.actionStatusData?.[actionStatus]?.label || ''
        }
    },
    {
        "accessorKey": "actionPerformer",
        "header": "Quien Realiza Acción",
        "cell": ({ row }) => {
            const actionPerformer = row.getValue('actionPerformer');
            return actionPerformer == 'agent' ? 'Vendedor' : actionPerformer == 'backoffice' ? 'Desde Backoffice' : '';
        }
    },
]

type DataStatusSale = { color: TailwindColor, label: string }

const getDataStatusSale = (status: 'sale' | 'cancel' | 'prospect'): DataStatusSale => {
    const mapSaleStatus: Record<string, DataStatusSale> = {
        'sale': {
            color: 'green',
            label: 'Vendido'
        },
        'cancel': {
            color: 'red',
            label: 'Cancelado'
        },
        'prospect': {
            color: 'blue',
            label: 'Prospect'
        }
    }

    return mapSaleStatus[status]
}

const ContactStatus = {
    New: 'new',
    Active: 'active',
    Reminder: 'reminder',
    OverdueReminder: 'overdue_reminder',
    Disregarded: 'disregarded',
    Sold: 'sold',
    Closed: 'closed'
} as const

export type ContactStatusType = typeof ContactStatus[keyof typeof ContactStatus]

export const getContactStatusData = (status: ContactStatusType) => {
    const { New, Active, Closed, Disregarded, OverdueReminder, Reminder, Sold } = ContactStatus

    const map: Record<ContactStatusType, {
        color: TailwindColor,
        label: string
    }> = {
        [New]: {
            color: 'primary',
            label: 'Nuevo'
        },
        [Active]: {
            color: 'primary',
            label: 'En seguimiento'
        },
        [Closed]: {
            color: 'orange',
            label: 'Recordatorio'
        },
        [Disregarded]: {
            color: 'red',
            label: 'Recordatorio vencido'
        },
        [OverdueReminder]: {
            color: 'red',
            label: 'Recordatorio vencido'
        },
        [Reminder]: {
            color: 'purple',
            label: 'Recordatorio'
        },
        [Sold]: {
            color: 'green',
            label: 'Vendido'
        },
    }

    return map[status] || {}
}