import { CardDescription } from '@/components/ui/card'
import { usePerformanceChartContext } from './context'
import { useCallback, useMemo } from 'react'
import moment from 'moment'
import { Skeleton } from '@/components/ui/skeleton'

export default function ModuleTitle() {
    const { periodSelected, goals, periodDate, isLoadingAnalyticChart } = usePerformanceChartContext()

    const formatedDate = useCallback((date) => moment(date).utcOffset(goals?.[0]?.offset || -180).format("DD/MM"), [goals])

    const strFormatDate = useMemo(() => {
        const { start, end } = periodDate || {}
        return `${formatedDate(start)} - ${formatedDate(end)}`
    }, [periodDate])

    return (
        <div className="flex items-start flex-col sm:flex-row sm:items-center text-start sm:gap-2.5">
            <span>
                Meta
                {periodSelected === "before" ? " anterior" : " actual"}
            </span>
            {
                !isLoadingAnalyticChart 
                ? (
                    <CardDescription className="text-[12px] font-medium">
                        {strFormatDate}
                    </CardDescription>
                )
                : <Skeleton className='w-[70px] rounded-sm h-[10px]'/>
            }
        </div>
    )
}
