
import { getGoalsFromSeller } from "@/actions/comercial/metas";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";

export default function useGetSummaryByPeriod({
    usersID,
    startDate,
    endDate,
    enabled,
    offset,
    queryKey = [`summary-of-goals-by-period`]
}: any) {

    const fetchSummaryOfPeriod = useCallback(async () => {
        try {
            const res = await getGoalsFromSeller({
                userId: usersID.join(','),
                endGoal: endDate,
                offset,
                startGoal: startDate
            });

            const data = await res.json();
            return data
        } catch (error) {
            console.error(error);
        }
    }, [usersID, startDate, endDate, offset]);

    const { data, isLoading, isError, refetch, ...rest } = useQuery({
        queryKey: [...queryKey, usersID],
        queryFn: fetchSummaryOfPeriod,
        enabled
    });

    return {
        querySummaryByPeriod: isLoading ? null : data,
        refetchSummary: refetch,
        isLoading,
        isError,
        ...rest
    }
}
