import { getCompany } from '@/actions/auth'
import { defaultGoalData } from '@/constants/goals'
import { getTimezonesForCountry } from 'countries-and-timezones'
import dayjs, { Dayjs } from "dayjs"
import _ from "lodash"
import moment from 'moment'

export const getAnalyticPeriod = ({
    goals,
    period,
    currentUserId
}) => {
    let contentGoals: Array<any> = goals || []

    let country = getCompany()?.country || 'uy'
    const timezone = getTimezonesForCountry(country.toUpperCase())[0].utcOffset
    const adjustedDate = (date: Date | string) => moment.utc(date).utcOffset(timezone, true).toDate();

    if (period === 'now') {
        let todayToUnix = new Date().getTime()

        contentGoals = contentGoals.filter(
            (goal) => {
                const start = adjustedDate(goal.start);
                const twoDaysAgoStartOfDayUnix = moment(start).subtract(1, 'days').startOf('day').unix()

                return (moment(start).startOf('day').unix() < todayToUnix || twoDaysAgoStartOfDayUnix < todayToUnix) &&
                    new Date(goal.end).getTime() > todayToUnix;
            });
    }

    const analyticPeriod = { ...defaultGoalData }

    for (let i = 0; i < contentGoals.length; ++i) {
        const goal = _.get(contentGoals[i], 'goal', [])

        const fieldToSum = {
            ...goal?.[0],
            salePlus: goal?.[1].sale || 0,
            salesPlusValue: goal?.[1].saleValue || 0
        }

        Object.keys(analyticPeriod)
            .forEach((keyField) => {
                analyticPeriod[keyField] += parseInt(fieldToSum?.[keyField] || 0)
            })

        goal.emptyGenerated = !!goal?.generated_void;
    }

    let idsSellers = Array.from(new Set(contentGoals.map(goal => goal.user._id)))

    if (!!currentUserId) {
        idsSellers = [currentUserId]
    }

    const firstGoal = Array.isArray(contentGoals) && contentGoals[0]
    let periodDate = { start: '', end: '' }

    const addOneDayToDate = (date: Dayjs) => date.add(1, 'day').toDate().toISOString()

    if (firstGoal) {
        const { start, end } = firstGoal || {}
        periodDate = { start, end }
    } else {
        periodDate =
        {
            start: addOneDayToDate(dayjs().startOf('week')),
            end: addOneDayToDate(dayjs().endOf('week'))
        }
    }

    return {
        goals: contentGoals,
        agentId: idsSellers,
        analyticPeriod,
        periodDate,
    }
}