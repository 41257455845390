export const dataGoal = {
    'tracing': {
        title: 'Seguimientos',
        href: '/reportes/tracings',
        filterActives: ['date', 'sellers']
    },
    'contacts': {
        title: 'Contactos',
        href: '/reportes/prospect',
        filterActives: ['date', 'agents', 'origin']
    },
    'sale': {
        title: 'Ventas',
        href: '/reportes/receipts',
        filterActives: ['date', 'sellers', 'status']
    },
    default: {
        href: '/reports'
    }
}

export const LIMIT_ELEMENTS = 50
export const filtersEmptyValue = {
    date: [],
    agents: [],
    origin: [],
    seller: [],
    status: [],
    users: []
}