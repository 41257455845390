const defaultGoalData = {
    sale: 0,
    saleValue: 0,
    tracing: 0,
    tracingValue: 0,
    salePlus: 0,
    contacts: 0,
    contactsValue: 0,
    emptyGenerated: false,
};

const metricsPerDefault = {
    percentage: 0,
    isPlusActive: false,
    currentPercentaje: 0,
    goalDifference: 0,
    goalPlusDifference: 0,
}

export { defaultGoalData, metricsPerDefault }