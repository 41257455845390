import { getCompany } from "@/actions/auth";
import _ from "lodash";
import { User } from "lucide-react";
import { Calendar, Locate, PieChart, UserCircle2 } from "lucide-react";
import moment from "moment";

export function dataFilters({ goal, periodSelected, sellers, status }): Array<any> {
    if (status?.error) return []

    const today = periodSelected === 'now' ? new Date() : new Date(goal?.end);
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const last7Days = new Date();
    last7Days.setDate(today.getDate() - 7);

    const last14Days = new Date();
    last14Days.setDate(today.getDate() - 14);
    const todayFormat = moment().utcOffset((goal?.offset && goal?.offset) ?? -180).endOf('date').toISOString()

    return [
        {
            code: 'date',
            label: 'Fecha',
            isMultiple: false,
            icon: <Calendar className="h-[15px] w-[15px]" />,
            options: [
                {
                    label: 'Hoy',
                    data: `${moment().startOf('date').utcOffset(goal.offset || -180).toISOString()}|${todayFormat}`
                },
                {
                    label: 'Ayer',
                    data: `${formatDate(yesterday, goal.offset)}|${todayFormat}`
                },
                {
                    label: 'Ultimos 7 dias',
                    data: `${formatDate(last7Days, goal.offset)}|${todayFormat}`
                },
                {
                    label: goal && `${moment(goal.start).format('DD/MM')} - ${moment(goal.end).format('DD/MM')}`,
                    data: goal && `${moment(goal.start).toISOString()}|${moment(goal.end).toISOString()}`
                }
            ]
        },
        {
            code: 'agents',
            isMultiple: true,
            label: 'Agentes',
            icon: <UserCircle2 className="h-[15px] w-[15px]" />,
            options: sellers
        },
        {
            code: 'origin',
            isMultiple: true,
            label: 'Origen',
            icon: <Locate className="h-[15px] w-[15px]" />,
            options: _.get(getCompany(), 'origins', []).filter(origin => origin.active).map(origin => (
                {
                    label: origin.name,
                    data: origin.code
                }
            ))
        },
        {
            code: 'sellers',
            isMultiple: true,
            label: 'Vendedor',
            icon: <User className="h-[15px] w-[15px]" />,
            options: sellers
        },
        {
            code: 'status',
            isMultiple: true,
            label: 'Estado',
            icon: <PieChart className="h-[15px] w-[15px]" />,
            options: status ? status?.map(status => (
                {
                    label: status.status,
                    data: status.status
                }
            )) : []
        }
    ]
}

const formatDate = (date, offset) => {
    return moment(date).utcOffset(offset || -180).startOf('date').toISOString()
}