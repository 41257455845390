import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { cn } from "@/lib/utils";
import '@/styles/scrollModify.css';
import { TableOptions, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { ReactNode, useEffect, useMemo, useState } from "react";
import { columnCheckBox } from "./libs/constants";
import { PaginationCard } from "./pagination-card";
import TableSkeleton from "./table-skeleton";

interface Props {
    table?: Record<string, any>
    className?: string
    onClickRow?: (value: any) => void
    checkBoxColumn?: boolean
    isLoading?: boolean
    rowCount?: number
    classNameHeader?: string
    paginationCount?: ReactNode
    onRowsSelected?: (data: Record<number, boolean>) => void
    page?: number
    onPageChange?: (page: number) => void
    paginationMode?: PaginationMode
    pageSize?: number
    classNameHead?: string
    disabledRow?: (row: any) => boolean
    empyMessage?: string
    classNameCell?: string
    withStripes?: boolean
    dataTable?: any
    activePagination?: boolean
}

export enum PaginationMode {
    Server = 'server',
    Local = 'local'
}

export function ReusableTable({
    table: externalTable,
    className = '',
    paginationMode = PaginationMode.Local,
    onClickRow,
    page = 1,
    activePagination = true,
    pageSize = 20,
    empyMessage = 'Sin resultados',
    rowCount = 0,
    onPageChange,
    isLoading,
    withStripes = false,
    classNameCell,
    disabledRow,
    checkBoxColumn = false,
    dataTable = {},
    onRowsSelected,
    classNameHeader,
    classNameHead
}: Props) {
    const [rowsSelecteds, setRowsSelecteds] = useState<any>({})
    const [dataReactTable, setDataReactTable] = useState<TableOptions<unknown>>({
        data: [],
        columns: [],
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: { pagination: { pageSize, pageIndex: 0 }, selectedRowIds: {} },
        ...dataTable
    })

    const table = externalTable ? externalTable : useReactTable(dataReactTable);

    const allColumns = table?.getAllColumns?.() || []

    const { pageIndex, pageSize: localPageSize } = table.getState().pagination || {}
    const showCountTo = (pageIndex * localPageSize) + localPageSize
    const totalCount = table.getCoreRowModel().rows.length

    const isServerTable = paginationMode === PaginationMode.Server

    useEffect(() => {
        if (!!externalTable) return;

        let dataOfTable: any = {
            ...dataReactTable,
            ...dataTable,
            initialState: { pagination: { pageSize, pageIndex: 0 }, selectedRowIds: {} },
        }

        table.setPageSize(pageSize)

        if (checkBoxColumn) {
            dataOfTable['columns'] = [columnCheckBox({ onRowSelected, onAllPageRowsSelected }), ...dataOfTable.columns]
        }

        setDataReactTable(dataOfTable)
    }, [checkBoxColumn, dataTable])

    useEffect(() => {
        const rowsSelected = table.getState().rowSelection
        onRowsSelected && onRowsSelected(rowsSelected)
    }, [table.getState().rowSelection])

    useEffect(() => {
        table.setState({ ...table.getState(), rowSelection: {} })
    }, [dataReactTable.data])

    const handlePagination = (mode: 'next' | 'prev') => () => {
        if (paginationMode === PaginationMode.Server) {
            let paginationIndex: number = page

            if (mode === 'next') {
                paginationIndex += 1
            } else {
                paginationIndex -= 1
            }

            onPageChange && onPageChange(paginationIndex)
            return;
        }

        if (mode === 'next') {
            table.nextPage()
        } else {
            table.previousPage()
        }
    }

    const showCount = paginationMode === PaginationMode.Local ? totalCount == 0 ? 0 : (pageIndex == 0 && totalCount < localPageSize) ? 1 : (pageIndex * localPageSize) : rowCount <= 1 ? rowCount : (((page - 1) * pageSize))

    const handleSelectRows = (rows) => {
        table.setRowSelection(Object.fromEntries(Object.keys(rows).map(key => [key, true])))
    }

    const onRowSelected = (row, value: boolean) => {
        const checked = !!value
        const { index, original } = row

        const allSelected = { ...rowsSelecteds }
        const realIndex = showCount + index

        if (checked) {
            allSelected[realIndex] = { index: realIndex, original }
        } else {
            delete allSelected[realIndex]
        }

        handleSelectRows(allSelected)
        setRowsSelecteds(allSelected)
    }

    const onAllPageRowsSelected = (value: boolean) => {
        const checked = !!value

        if (!checked) {
            const allValuesToArray: any = Object.values(rowsSelecteds)
            allValuesToArray.splice(showCount, allValuesToArray.length)
            const allSelected = allValuesToArray.map(({ original, index }) => ({ original, index: showCount + index }))
            handleSelectRows(allSelected)
            return setRowsSelecteds(allSelected)
        }

        const allValues = Object.fromEntries(
            table.getRowModel().flatRows.splice(showCount, table.getRowModel().flatRows.length).map(({ original, index }) => {
                const realIndex = showCount + index
                return [realIndex, ({ original, index: realIndex })]
            }))

        const allSelected = { ...rowsSelecteds, ...allValues }
        handleSelectRows(allSelected)
        return setRowsSelecteds(allSelected)
    }

    if (!dataReactTable) return null;

    return <>
        <div className='w-full flex-col overflow-hidden flex-grow relative'>
            <div className="flex-col flex-grow h-full relative flex">
                <div className='w-full overflow-hidden flex-grow rounded-lg border  border-gray-300 shadow-2x relative'>
                    <div className='relative overflow-auto  flex h-full items-start justify-start modifyScroll l'>
                        <Table className={cn(`min-w-full rounded-none  w-max absolute`, className)}>
                            <TableHeader className="border-none">
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <>
                                        <TableRow key={headerGroup.id} className={cn('w-full bg-gray-50  sticky h-[60px]   hover:bg-muted top-0 z-[2] border-none', classNameHeader)}>
                                            {headerGroup.headers.map((header) =>
                                                header.isPlaceholder ? null : (
                                                    <TableHead key={header.id} className={cn('[&>div]:h-full [&>div]:border-b [&>div]:border-gray-300 min-w-[150px] [&>div]:w-full [&>div]:flex [&>div]:items-center [&>div]:px-4 [&>div]:truncate  px-0 text-[13px] uppercase truncate relative', classNameHead)}>
                                                        <div>
                                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                                        </div>
                                                    </TableHead>
                                                )
                                            )}
                                        </TableRow>
                                        <div></div>
                                    </>
                                ))}
                            </TableHeader>
                            <TableBody>
                                {
                                    isLoading
                                        ? <TableSkeleton headerGroup={table.getHeaderGroups()[0].headers} />
                                        : table.getRowModel().rows?.length > 0 ? (
                                            table.getRowModel().rows.map((row: any, indexRow) => {
                                                const isItEven = withStripes && (indexRow % 2) === 0
                                                const isDisabled = disabledRow && disabledRow(row)

                                                return (
                                                    <TableRow
                                                        className={`
                                                        ${isDisabled ? 'opacity-75 cursor-not-allowed' : ''}
                                                        ${row?.original?.prospectId ? 'cursor-pointer' : ''} h-6 max-h-6 w-full`}
                                                        key={row.id}>
                                                        {row.getVisibleCells().map((cell) => {
                                                            const isClicked = !isDisabled && onClickRow && cell.column.accessorFn
                                                            return <TableCell
                                                                onClick={() => isClicked && onClickRow(row?.original)}
                                                                key={cell.id}
                                                                className={cn(`h-[45px] min-h-[45px] ${withStripes && !isItEven ? 'bg-gray-50' : ''} relative p-0 px-3 py-1 ${isClicked ? 'cursor-pointer' : ''}`, classNameCell)}>
                                                                <div className="max-h-[45px] text-[13px]  text-gray-500 font-medium flex items-center max-w-10 h-full truncate">
                                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                                </div>
                                                                <div></div>
                                                            </TableCell>
                                                        })}
                                                    </TableRow>
                                                )
                                            })
                                        ) : <TableRow>
                                            <TableCell colSpan={allColumns.length} className="h-full hover:bg-transparent text-center">
                                                {empyMessage}
                                            </TableCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </div>
                </div>
                {
                    activePagination && <>
                        <PaginationCard
                            isLoading={isLoading}
                            onPrevPagination={handlePagination('prev')}
                            onNextPagination={handlePagination('next')}
                            leftDisabled={isServerTable ? page <= 1 : !table.getCanPreviousPage()}
                            rightDisabled={isServerTable ? Math.ceil((page) * pageSize) >= rowCount : !table.getCanNextPage()}
                            PaginationCount={
                                (isServerTable) ? <>
                                    <PaginationCount
                                        showCount={rowCount <= 1 ? rowCount : (((page - 1) * pageSize)) || 1}
                                        showCountTo={Math.min(((page) * pageSize), rowCount)}
                                        totalCount={rowCount}
                                    />
                                </>
                                    : <PaginationCount
                                        showCount={totalCount == 0 ? 0 : (pageIndex == 0 && totalCount < localPageSize) ? 1 : (pageIndex * localPageSize)}
                                        showCountTo={showCountTo >= totalCount ? totalCount : showCountTo}
                                        totalCount={totalCount}
                                    />
                            }
                        />
                    </>
                }
            </div>
        </div>
    </>
}

export function PaginationCount({
    showCount,
    showCountTo,
    totalCount
}) {
    return (
        <p className="text-gray-700 font-medium text-[14px]">
            Mostrando {showCount} a {showCountTo} de {totalCount} resultados
        </p>
    )
}