import { getUserByRol } from '@/actions/comercial/metas'
import { IUserByRol } from '@/interfaces/Interface'
import { role } from '@/models/User'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useMemo } from 'react'

export default function useGetUserByRol(
    {
        roles,
        queryKey = ['all-sellers-user'],
        enabled
    }:
        {
            roles: Array<role> | role,
            queryKey?: Array<string>,
            enabled?: boolean
        }
) {

    const { data, isLoading, isError } =
        useQuery({
            queryKey,
            queryFn: () => getUserByRol({ roles: [roles].flat() }),
            enabled
        })

    const dictionaryList = useMemo(() => {
        let list: IUserByRol[] = data || []
        if (!Array.isArray(list)) list = [];
        return _.keyBy(list, '_id');
    }, [data])

    return { users: data || [], isError, isLoading, dictionaryList }
}
