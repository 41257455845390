import axios from 'axios'
const API = process.env.API
const API_NEXT = process.env.API_NEXT
import { getCookie, getLocalStorage } from '../auth'
import moment from 'moment'
import { fetcher } from '@/helpers/fetcher'



export const upload = (group, form) => {

    return axios.post(`${API}/goal/${group}/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const getDailyStats = (user) => {
    return axios.get(`${API}/weekgoal/stats/${user}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const getDailyAlerts = (user) => {
    return axios.get(`${API}/weekgoal/alerts/${user}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const getDailyAlertsGroup = (group) => {
    return axios.get(`${API}/weekgoal/alerts/group/${group}`,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const uploadUser = (form) => {

    return axios.post(`${API}/goal/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}


export const downloadGoal = (type, user, week = false, months, grouped = false) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months) {
        query.push(`months=${months.join(',')}`)
    }

    if (grouped) {
        query.push('grouped=true')
    }

    if (week) {
        query.push(`date=now`)
    }
    return axios({
        url: `${API}/weekgoal/download${query.length > 0 ? '?' + query.join('&') : ''}`,
        method: 'GET',
        responseType: 'blob', // important
        headers: {
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}

export const downloadGoalTemplate = (year, month, week) => {
    return axios({
        url: `${API}/weekgoal/download/${year}/${month}/template.xlsx${week ? '?weeks=' + week : ''}}`,
        method: 'POST',
        responseType: 'blob', // important
        headers: {
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}



export const weekgoalDetail = (type, user, week, months) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months) {
        query.push(`months=${months.join(',')}`)
    }

    if (week) {
        if (week == 'before') {
            query.push(`date=before`)

        } else {
            query.push(`date=now`)
        }
    }
    return fetch(`${API}/weekgoal/detail${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const uploadNewUser = (form) => {

    return axios.post(`${API}/weekgoal/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const uploadNewUserFive = (form) => {

    return axios.post(`${API}/weekgoal/week5/upload`, form,
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + getCookie('token')
            }
        })
}

export const getWeekGoal = (type, user, week, months, yesterday, group = undefined) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months && week != 'before') {
        query.push(`months=${months.join(',')}`)
    }

    if (week) {
        query.push(`date=${week}`)
    }
    if (group) {
        query.push('grouped=true')
    }

    if (yesterday) {
        query.push('yesterday=true')
    }
    return fetch(`${API}/weekgoal${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getWeekGoalById = (type, user, week, months, yesterday, groupid) => {
    let query = []
    if (type) {
        query.push(`type=${type}`)
    }
    if (user) {
        query.push(`user=${user}`)
    }

    if (months && week != 'before') {
        query.push(`months=${months.join(',')}`)
    }
    if (week) {
        if (week == 'before') {
            query.push(`date=before`)

        } else {
            query.push(`date=now`)
        }
    }
    if (yesterday) {
        query.push('yesterday=true')
    }
    return fetch(`${API}/weekgoal/${groupid}${query.length > 0 ? '?' + query.join('&') : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getAll = (group) => {
    return fetch(`${API}/goal/${group}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const get = () => {
    return fetch(`${API}/goal`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}


export const getVentas = (page) => {
    return fetch(`${API}/goal?page=${page}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getVentasGrupo = (page) => {
    return fetch(`${API}/goal/getGroup?page=${page || 1}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const getGoalsFromSeller = ({ userId, startGoal, endGoal, offset }) => {
    return fetch(`${API_NEXT}/sellersgoals?users=${userId}&startDate=${startGoal}&endDate=${endGoal}&offset=${offset}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}

export const getListUserByGoal = ({ type, limit = 20, page = 1, search, filters }) => {
    const querys = Object.entries(filters).map(([key, value]) => {
        if (key === 'date') {
            const converterStringISOToArray = value[0].split('|')
            return `&startDate=${converterStringISOToArray[0]}&endDate=${converterStringISOToArray[1]}`
        }
        return `&${key}=${value.join(',')}`
    }).filter(filter => filter)

    return fetch(`${API_NEXT}/singleGoal?type=${type}&page=${page}&limit=${limit}${search ? `&search=${search}` : ''
        }${querys.length > 0
            ? `${querys.join('')}`
            : ''}`, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    })
}

export const getUserByRol = ({ roles = [] }) => {
    const converterArrayToString = roles.join(',')
    const url = `${API_NEXT}/singleGoal/getAUserWithRole?roles=${converterArrayToString}`
  
    return fetch(url, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    }).then(res => {
        if (!res.ok) {
            throw Error('Se produjo un error al obtener los datos.')
        }
        return res.json()
    })
        .catch(e => {
            throw Error('Se produjo un error al obtener los datos.')
        })
}

export const getAllStatus = () => {
    const url = `${API_NEXT}/singleGoal/statusByGroup`

    return fetch(url, {
        method: 'get',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        }
    }).then(res => res.json())
} 