import { calculatePercentage } from "@/helpers/calculatePercentage";
import { calculateExpectedGoal } from "@/helpers/objectiveChart";
import { PerformanceCardInterface } from "@/interfaces/Interface";
import { TYPES_CHART } from '@/interfaces/config';
import React, { Suspense, useMemo, useState } from "react";
import { SkeletonChart } from "./skeleton-chart";
const PerformanceCardDetailed = React.lazy(() => import("./PerformanceCardDetailed"))
const PerformanceCardSimplified = React.lazy(() => import("./PerformanceCardSimplified"))

function PerformanceCard({
    title,
    goalExpected,
    goalPlus,
    goalValue,
    onClick,
    selectedUser,
    user,
    startAndEndGoal,
    chartType,
    activeLine,
    goalsAchieved,
    periodSelected,
}: PerformanceCardInterface) {

    const [goalOfTheDay, setGoalOfTheDay] = useState(0)

    const goalMetric = useMemo(() => {
        const { start, end }: any = startAndEndGoal

        const data = {
            endDate: end,
            startDate: start,
            goal: goalExpected,
            dateToBreak: new Date()
        }

        let goalExpectedPerDay = calculateExpectedGoal(data)
        let goalOfTheDayValue = goalExpectedPerDay[goalExpectedPerDay.length - 1]

        let currentPercentaje = calculatePercentage(goalValue, goalOfTheDayValue) || 0
        let percentageGoal = calculatePercentage(goalValue, goalExpected) || 0

        const goalDifference = goalValue - goalExpected;
        const goalPlusDifference = goalPlus ? goalPlus - goalValue : 0;
        let isPlusActive = false;

        if (percentageGoal >= 100 && goalPlus && goalPlus > 0) {
            percentageGoal = calculatePercentage(goalValue, goalPlus) || 0
            isPlusActive = true;
        }

        if (percentageGoal === Infinity) {
            percentageGoal = 100
        }

        const obj = {
            percentage: percentageGoal,
            goalDifference,
            currentPercentaje,
            goalPlusDifference,
            isPlusActive: isPlusActive
        }

        setGoalOfTheDay(goalOfTheDayValue)
        return obj

    }, [
        title,
        goalExpected,
        goalPlus,
        goalValue,
        onClick,
        selectedUser,
        user,
        startAndEndGoal,
        chartType,
        activeLine,
        goalsAchieved,
        periodSelected,
    ])

    const dataOfCard = {
        title,
        periodSelected,
        startAndEndGoal,
        goalExpected,
        selectedUser,
        goalPlus,
        activeLine,
        goalsAchieved,
        goalValue,
        onClick,
        user,
        goalMetric,
        goalOfTheDay
    }
    
    return (
        <>
            {
                chartType === TYPES_CHART.DETAILED
                && <Suspense fallback={<SkeletonChart />}>
                    <PerformanceCardDetailed
                        {...dataOfCard}
                    />
                </Suspense>
            }
            {
                chartType === TYPES_CHART.SIMPLIFIED
                && <Suspense fallback={<SkeletonChart />}>
                    <PerformanceCardSimplified
                        {...dataOfCard}
                    />
                </Suspense>
            }
        </>
    )
}

export default PerformanceCard