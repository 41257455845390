import { chartsOptions } from "@/components/StatusCard/GraphicsArea";
import { weeksInitials } from "@/constants/dates";
import { ranges } from "@/constants/ranges";
import moment from "moment";
interface ICalculateExpectedGoal {
    endDate: Date | null | string
    startDate: Date | null | string
    goal: number
    addNonWorkingDays?: Array<number>,
    dateToBreak?: null | Date
}

export function generateAllGoalsByGroup(group) {
    const goals = { sale: 0, contacts: 0, tracing: 0 };

    if (group.users) {
        group.users.forEach(user => {
            chartsOptions.forEach(data => {
                if (user.goal.goal) {
                    goals[data.keyGoal] += user.goal.goal[0][data.keyGoal];
                }
            });
        });
    }

    if (Array.isArray(group?.group) && group.group.length > 0) {
        const groupsList = group?.group || []
        groupsList.forEach(subGroup => {
            const subGroupGoals = generateAllGoalsByGroup(subGroup);
            for (const key in goals) {
                if (goals.hasOwnProperty(key)) {
                    goals[key] += subGroupGoals[key];
                }
            }
        });
    }

    return goals;
}

export function generateWeeklabel({ start, end }: { start: Date, end: Date }): string[] {
    const contentData: string[] = []

    for (let currentDate = moment(start).startOf('day');
        currentDate.diff(end, 'day') <= 0;
        currentDate = currentDate.add(1, 'day')) {
        if (currentDate.date() === 1) {
            contentData.push(currentDate.format('D/M'))
        } else {
            contentData.push(weeksInitials[currentDate.day()])
        }
    }

    return contentData
}

export function calculateExpectedGoal({
    endDate,
    goal,
    startDate,
    addNonWorkingDays = [0, 6],
    dateToBreak = null }: ICalculateExpectedGoal): Array<number> {
    if (!startDate || !endDate) return []


    try {
        if (goal === 0) return new Array(moment(endDate || moment().endOf('week')).diff(moment(startDate || moment().startOf('week')), 'days')).fill(0)
    } catch {
        return []
    }

    const nonWorkingDays = Array.from(new Set([...addNonWorkingDays]))

    let day = moment(startDate).startOf('days');
    const contentExpectedPerday: number[] = [];
    let cumulatedGoalsDays = 0;
    let realDays = 0;

    const endDateConverter = moment(endDate)

    for (let i = moment(startDate); i <= endDateConverter; i.add(1, 'days')) {
        if (!nonWorkingDays.includes(day.day())) {
            realDays += 1
        }
        day.add(1, 'days');
    }

    day = moment(startDate).startOf('day');

    for (let i = moment(startDate); i <= endDateConverter; i.add(1, 'days')) {

        const dayToCompare = day.day()
        if (!nonWorkingDays.includes(dayToCompare)) {
            cumulatedGoalsDays += 1;
        }

        day.add(1, 'days');
        contentExpectedPerday.push(Math.round((goal / realDays) * cumulatedGoalsDays))

        if (dateToBreak && i.format('YYYY/MM/DD') === moment(dateToBreak).format('YYYY/MM/DD')) break;
    }

    return contentExpectedPerday
}

export function formatNumbers(number: number) {
    if (isNaN(number)) return number
    for (let index = ranges.length - 1; index >= 0; index--) {
        if (number > ranges[index].divider) {
            let quotient = number / ranges[index].divider;
            if (quotient < 10) {
                quotient = Math.floor(quotient * 10) / 10;
            } else {
                quotient = Math.floor(quotient);
            }
            return quotient.toString() + ranges[index].suffix;
        }
    }
    return number.toString();
}

export function generateMetricDefault(startDate: string | Date, endDate: string | Date): Record<string, number> {
    const defaultValues = new Map()
    const numStart = moment(startDate).startOf('days')

    for (let dateToAdd = numStart; dateToAdd.diff(moment(endDate).startOf('days'), 'days') <= 0; dateToAdd = dateToAdd.add(1, 'days')) {
        defaultValues.set(dateToAdd.format('YYYY/MM/DD'), 0)
    }

    return Object.fromEntries(defaultValues)
}