import { z } from "zod"

export const schemaFormAddTemplate = z.object({
    name: z.string().min(1, { message: 'El nombre de la plantilla es requerido' }),
    rows_to_skip: z.number(),
    file: z.any().optional()
})

export const defaultErrors = { errors: 0, create: 0, documentToUpdate: 0 }

export const defaultValuesTemplate = {
    name: '',
    file: null,
    rows_to_skip: 0
}

export const Steps = {
    SelectFile: 'selectFile',
    SelectRow: 'selectRow',
    SelectFields: 'selectFiels',
    SelectFileOptions: 'selectOptionsFiels',
    ConfigureRequiredFields: 'configureRequiredFields',
    End: 'end'
} 

export const textTypeInEs = {
    'INCOMING': 'Entrante',
    'UNKNOWN': 'Desconocida',
    'MISSED': 'Perdida',
    'OUTGOING': 'Saliente'
}

export const titlesStep = {
    [Steps.SelectFile]: 'Seleccionar archivo Excel',
    [Steps.SelectFileOptions]: 'Configurar opciones',
    [Steps.SelectFields]: 'Configuración de campos',
    [Steps.ConfigureRequiredFields]: 'Configuración de campos requeridos',
    [Steps.SelectRow]: 'Seleccionar filas que se saltearán en el archivo'
}