import { getGoalsFromSeller } from "@/actions/comercial/metas";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { cumulativelyAddAnArray } from "@/helpers/arrayFunctions";
import { calculateExpectedGoal, generateAllGoalsByGroup, generateMetricDefault, generateWeeklabel } from '@/helpers/objectiveChart';
import { setTypeChart } from "@/redux/slices/config";
import '@/styles/scrollModify.css';
import { AlertCircle, ArrowLeft, ExternalLink, Loader2 } from 'lucide-react';
import dynamic from 'next/dynamic';
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import VisualDataSVG, { NotVisualDataSVG } from "../Svgs/Metrics";
import { Button } from '../ui/button';
import { DialogDescription, DialogTitle } from '../ui/dialog';
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

export const chartsOptions = [{
    title: 'Contactos',
    keyGoal: 'contacts'
}, {
    title: 'Seguimientos',
    keyGoal: 'tracing'
}, {
    title: 'Ventas',
    keyGoal: 'sale'
}]

export default function GraphicsArea({ selectedSeller, handleDeleteSeller, onCLoseModal, isGroupSelected }) {
    const assignedGoal = useMemo(() => selectedSeller?.goal ?? [], [selectedSeller])
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [sellerArchivedGoal, setSellerArchivedGoal] = useState({ sale: [], contacts: [], tracing: [] })

    const dispatch = useDispatch()
    const router = useRouter()

    const generateAllIdsUser = (group): Array<string> => {
        let users = [];

        if (group.users) {
            users = group.users.map(user => user._id);
        }

        if (Array.isArray(group.group) && group.group.length > 0) {
            const groupUsers = group.group.map(subGroup => generateAllIdsUser(subGroup));
            users = users.concat(...groupUsers);
        }

        return users;
    }

    useEffect(() => {
        if (selectedSeller && selectedSeller.goalPeriod) {
            setError('')
            const { _id, goalPeriod, goal } = selectedSeller

            const generateDefaultMetricGoal = generateMetricDefault(goalPeriod.start, new Date())
            const fieldsNameGoal = chartsOptions.map(name => name.keyGoal)

            let newObjectMetrics: any = {}

            fieldsNameGoal.forEach(name => newObjectMetrics[name] = { ...generateDefaultMetricGoal })

            const startGoalToFetch = isGroupSelected ? goalPeriod?.start : goal?.start
            const endGoalToFetch = isGroupSelected ? goalPeriod?.end : goal?.end

            const groupsId = isGroupSelected && generateAllIdsUser(selectedSeller).join(',')
            if (isGroupSelected && (!groupsId || groupsId.length == 0)) return;
            setLoading(true)

            getGoalsFromSeller({
                userId: isGroupSelected
                    ? groupsId
                    : _id, startGoal: startGoalToFetch, endGoal: endGoalToFetch, offset: goal?.offset ?? -180
            })
                .then(async resFetch => {
                    let data = await resFetch.json()

                    Object.entries(data).map(([key, value]: [string, { string: number }]) => {
                        Object.entries(value).forEach(([keyDate, dateValue]) => {
                            newObjectMetrics[key][keyDate] = dateValue
                        })
                    })

                    Object.entries(newObjectMetrics)
                        .forEach(([key, value]: [string, { string: number }]) => newObjectMetrics[key] = Object.values(value))

                    setSellerArchivedGoal(newObjectMetrics)
                })
                .catch(() => setError('Ocurrió un error al obtener las métricas del vendedor. Por favor, inténtalo de nuevo.'))
                .finally(() => setLoading(false))
        }
    }, [selectedSeller, isGroupSelected])

    const charts: Array<any> = useMemo(() => {
        if (!selectedSeller || (!isGroupSelected && !selectedSeller?.goal?.goal?.[0])) return []

        return chartsOptions.map(({ keyGoal, title }) => {
            const { start, end } = selectedSeller.goalPeriod

            const allGoalsOfGroup = isGroupSelected && generateAllGoalsByGroup(selectedSeller)

            const cumulativeGoal = cumulativelyAddAnArray(sellerArchivedGoal[keyGoal] || [])
            const labels = generateWeeklabel({ end, start })

            const expectedPerDay = calculateExpectedGoal(
                {
                    endDate: end,
                    startDate: new Date(start),
                    goal: isGroupSelected ? allGoalsOfGroup[keyGoal] : selectedSeller?.goal?.goal?.[0]?.[keyGoal] || 0
                }
            )

            return {
                option: {
                    chart: {
                        width: "100%",
                        type: 'line',
                        toolbar: {
                            show: false,
                        },
                        zoom: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: 'smooth',
                        dashArray: [0, 3],
                        lineWidth: 0.1,
                    },
                    title: {
                        text: '',
                        align: 'left',
                        size: 12,
                        style: {
                            fontSize: '15px',
                            fontWeight: 500,
                        },
                    },
                    xaxis: {
                        categories: labels,
                    },
                    legend: {
                        position: 'top',
                    },
                    colors: ['#0180FF', '#d6d6d6']
                },
                series: [{
                    name: title,
                    data: cumulativeGoal,
                    labels: {
                        show: false,
                    },
                }, {
                    name: 'Esperado',
                    data: expectedPerDay,
                    labels: {
                        show: false,
                    },
                }]
            }
        })
    }, [assignedGoal, selectedSeller, sellerArchivedGoal])

    const handleViewPanel = () => {
        if (!isGroupSelected) dispatch(setTypeChart("detailed"))
        const query = {}

        const id = selectedSeller._id

        if (!isGroupSelected) {
            query['view-user'] = id
        } else if (isGroupSelected) {
            query['view-group'] = id
        }

        router.push({
            pathname: '/',
            query,
        })
        onCLoseModal()
    }

    return <div className={`h-auto  lg:w-[320px] w-full flex flex-col lg:bg-[#fafafa] bg-white relative   ${selectedSeller ? 'flex' : 'hidden'} lg:flex  flex-col relative max-h-[100%] h-full`}>
        <div className='flex  items-center  relative gap-2'>
            <button className='w-[30px] left-4 lg:hidden block h-[30px] rounded-full shrink-0' onClick={handleDeleteSeller}>
                <ArrowLeft size={22} />
            </button>
            <div className='lg:text-start flex px-2 text-[13px] pt-3 mb-2 pl-4 flex-col text-center'>
                <DialogTitle className='text-[17px]'>Resumen {isGroupSelected ? 'del grupo' : 'del vendedor'}</DialogTitle>
                <div className="flex items-center">
                    {!isGroupSelected && selectedSeller?.color && <span className="w-2 mr-1 h-2 relative rounded-full" style={{ backgroundColor: selectedSeller.color }}></span>}
                    <DialogDescription className='text-[14px]'>
                        {selectedSeller?.name} {selectedSeller?.lastname}
                    </DialogDescription>
                </div>
            </div>
        </div>
        <div className="relative w-full flex-grow flex flex-col">
            <div className="absolute flex flex-col items-center justify-center w-full h-full overflow-y-auto overflow-x-hidden max-w-[100%]">
                {
                    error
                        ? <div className='px-5'>
                            <Alert variant="destructive">
                                <AlertCircle className="h-4 w-4" />
                                <AlertTitle>Error</AlertTitle>
                                <AlertDescription>
                                    {error}
                                </AlertDescription>
                            </Alert>
                        </div> : selectedSeller
                            ? isLoading
                                ? <div className='w-full flex-grow flex justify-center items-center'>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin text-primary " />
                                </div>
                                :
                                charts.length > 0
                                    ? (
                                        <div className="flex-grow w-full h-full max-w-[100%] flex px-1 pr-3 flex-col">
                                            {charts.map((chart, inx) => (
                                                <>
                                                    <Chart
                                                        key={`chart${inx}`}
                                                        options={chart.option}
                                                        series={chart.series}
                                                        type="line"></Chart>
                                                </>
                                            ))}
                                        </div>
                                    )
                                    : <div className='flex px-5 justify-center flex-grow text-center  w-full items-center flex-col gap-2'>
                                        <NotVisualDataSVG height="170" width="170" />
                                        <h5 className="text-[14px] text-neutral-700 mt-3">El Vendedor no tiene una Meta Establecida</h5>
                                    </div>
                            : <div className="flex justify-center px-5 text-center flex-grow w-full items-center flex-col gap-2">
                                <span className="text-primary">
                                    <VisualDataSVG height="130" width="130" />
                                </span>
                                <h5 className="text-[13px] text-neutral-700 text-center">Selecciona un Vendedor o Grupo para Visualizar Métricas</h5>
                            </div>
                }
            </div>
        </div>
        {
            !isLoading && charts.length > 0 && (
                <div className="px-4 mt-1">
                    <Button
                        className='w-full h-9 rounded-[4px] mb-2 flex items-center gap-1'
                        variant={'default'}
                        onClick={() => handleViewPanel()}
                    >
                        <ExternalLink size={17} />
                        Panel de {selectedSeller.name}
                    </Button>
                </div>
            )
        }
    </div >
}

