import { NormalChart } from "@/components/Icons/charts"
import { Button } from "@/components/ui/button"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { Tabs, TabsList } from "@/components/ui/tabs"
import { TYPES_CHART } from "@/interfaces/config"
import { LineChart } from "lucide-react"
import { usePerformanceChartContext } from "../context"
import TabModify from "./tab-modify"

export default function ChartAction() {
    const {
        periodSelected,
        handleChangeChartType,
        chartType,
        handleChangePeriodSelected
    } = usePerformanceChartContext()

    return (
        <div className="flex items-center gap-2">
            <SimpleTooltip
                sideOffset={5}
                content={`Ver visualización ${chartType === TYPES_CHART['DETAILED']
                    ? "simplificada"
                    : "detallada"
                    }`
                }
            >
                <Button
                    variant={"ghost"}
                    onClick={() => handleChangeChartType()}
                    style={{
                        boxShadow: "0px 1px 3px rgba(0 0 0 / 15%)",
                    }}
                    className="h-[28px] rounded-sm shadow-sm bg-[#F1F3F4] w-[28px]"
                    size={"icon"}
                >
                    {
                        chartType === TYPES_CHART['DETAILED']
                            ? <LineChart className="w-[18px] h-[18px]" />
                            : <NormalChart className="w-[18px] h-[18px]" />
                    }
                </Button>
            </SimpleTooltip>
            <Tabs
                defaultValue={periodSelected}
                className="w-auto"
                onValueChange={handleChangePeriodSelected}
            >
                <TabsList
                    className="grid w-full p-0 h-auto grid-cols-2"
                    style={{
                        boxShadow: "0px 1px 3px rgba(0 0 0 / 18%)",
                    }}
                >
                    {[
                        ["now", "Actual"],
                        ["before", "Anterior"],
                    ].map(([value, label]: [string, string]) => (
                        <TabModify label={label} value={value} key={value} />
                    ))}
                </TabsList>
            </Tabs>
        </div>
    )
}
