import { getWeekGoal } from '@/actions/comercial/metas';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

export default function useGetGoals({
    period = 'now',
    byUser,
    yesterday = false,
    months = ["0"],
    enabled = true,
    group = undefined,
    queryKey = ["goals-data-summary"]
}) {
    const fetchData = async (user) => {
        const retriveType = period === 'now' ? 'calculate' : 'calculate';

        const result = await getWeekGoal(retriveType, user ?? "all", period, months, yesterday, group);

        const listGoals = [...result.goals].sort(
            (a, b) => {
                const yearDiff = parseInt(a.year) - parseInt(b.year);
                if (yearDiff !== 0) return yearDiff;
                const monthDiff = parseInt(a.month) - parseInt(b.month);
                if (monthDiff !== 0) return monthDiff;
                const userComparison = `${a.user.name} ${a.user.lastname}`.localeCompare(`${b.user.name} ${b.user.lastname}`);
                if (userComparison !== 0) return userComparison;
                return new Date(a.start).getTime() - new Date(b.start).getTime();
            }
        );

        const obj = { list: listGoals, users: result.users };
        return obj
    }

    const { data, isError, isLoading, refetch, ...rest } = useQuery({
        queryKey,
        queryFn: () => fetchData(byUser),
        enabled: !!period && enabled
    });

    return {
        result: data?.list || [],
        isError,
        isLoading,
        refetchGoal: refetch,
        ...rest
    };
}
