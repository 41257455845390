import { Checkbox } from "@/components/ui/checkbox"

export const columnCheckBox = ({ onRowSelected, onAllPageRowsSelected }) => (
    {
        id: "select",
        header: ({ table }) => (
            <Checkbox
                className="border w-5 h-5"
                checked={
                    table.getIsAllPageRowsSelected() ||
                    (table.getIsSomePageRowsSelected() && "indeterminate")
                }
                // onCheckedChange={(value) => onAllPageRowsSelected(!!value)}
                onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                aria-label="Select all"
            />
        ),
        cell: ({ row }) => (
            <Checkbox
                className="border w-5 h-5"
                checked={row.getIsSelected()}
                // onCheckedChange={(value) => onRowSelected(row, value)}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
            />
        ),
        enableSorting: false,
        enableHiding: false,
    }
)