import Module from "@/components/core/Module"
import {
    PerformanceInterface
} from "@/interfaces/Interface"
import ChartAction from "./actions"
import { PerformanceChartProvider } from "./context"
import ModuleTitle from "./module-title"
import PerformanceCharts from "./performance-charts"

export default function Performance({
    user,
    selectedUserSyncHistory
}: PerformanceInterface) {
    return (
        <>
            <PerformanceChartProvider>
                <Module
                    titleElement={<ModuleTitle />}
                    action={<ChartAction />}
                    className="py-0"
                >
                    <PerformanceCharts
                        userSelected={user}
                        selectedUserSyncHistory={selectedUserSyncHistory}
                    />
                </Module>
            </PerformanceChartProvider>
        </>
    )
}

